/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { tr } from 'date-fns/locale'

export function AsideMenuMain() {
  const intl = useIntl()
  //select all the element with class "kt-aside-menu__item"
  const menuItems = document.querySelectorAll('.module-menu')
  // mouse over event
  // const handleMouseOver = (e:any) => {
  //   console.log('mouse over' , e.target)
  //   // select all the element with class "kt-aside-menu__item"
  //   const menuItems = document.querySelectorAll('.module-menu')
  //   // loop through the menu items
  //   for (let i = 0; i < menuItems.length; i++) {
  //     // remove the class "open"
  //     menuItems[i].classList.remove('open')
  //   }
  //   // add the class "open"
  //   e.target.classList.add('open')

  // }
  // // mouse out event
  // const handleMouseOut = (e:any) => {
  //   // remove the class "open"
  //   // alert('mouse out')
  //   e.target.classList.remove('open')
  // }

  // 5LD672MYM5
  const [roles, setRoles] = React.useState<string[]>([]);
  const getRoles = () => {
    // Step 1: Retrieve JWT from Local Storage
    const jwtToken = localStorage.getItem('kt-auth-react-v') || '';

    // Step 2: Decode the Token
    const decodedToken = decodeJWT(jwtToken);

    // Step 3: Extract Roles from Decoded Token
    if (!decodedToken || !decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]) {      
      let roles_ = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
      if(roles_)
      {
        // if roles type string
        if(typeof roles_ === 'string')
        {
          roles_ = [roles_];
        } else
        roles_ = roles_.map((role: string) => role.toUpperCase());
      }
      else 
      {
        roles_ = [];
        alert("you don't have any roles")
        //logout 
        localStorage.removeItem('kt-auth-react-v');
        window.location.href = '/auth/login';
      }
    
      // .filter((role: string) => role !== 'ADMIN');
      // Step 4: Set Roles to State
      setRoles(roles_);
    }

    // Function to decode JWT token (assuming it's a standard JWT)
    function decodeJWT(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(window.atob(base64));
    }
}
  // const roles_list = [
  // {
  //   name: 'ADMIN',
  //   routes: [
  //     '*',
  //     '/dashboard'
  //   ]
  // },
  // {
  //   name: 'ORDER MANAGEMENT',
  //   routes: [
  //     '/ordersManagement/*',
  //     '/reports/*',
  //     '/dashboard'
  //   ]
  // },
  // {
  //   name: 'FINANCE',
  //   routes: [
  //     '/payments/*',
  //     '/dashboard'
  //   ]
  // },
  // {
  //   name: 'CUSTOMER_SERVICE',
  //   routes: [
  //     '/accounts/*',
  //     '/airalo/*',
  //     '/cards/*',
  //     '/pyament/*',
  //     '/dashboard/*',
  //   ]
  // },
  // {
  //   name: 'CONTENT MANAGEMENT',
  //   routes: [
  //     '/operators/*',
  //     '/plans/*',
  //     '/configurations/*',
  //     '/notification/*',
  //     '/dashboard'
  //   ]
  // }
  //   // 'FINANCE',
  //   // 'UI',
  //   // 'CUSTOMER SERVICE',
  //   // 'PROVIDER',
  //   // 'DEVELOPER',
  //   // 'CONTENT MANAGEMENT',
  //   // 'PARTNER MANAGEMENT',
  //   // 'SUPER ADMIN',
  //   // 'UX',
  //   // "ACCOUNT MANAGEMENT",
  //   // "ORDER MANAGEMENT"
  // ]
  

  

  React.useEffect(() => {
    getRoles();
  }, []);

  if (true) 
  return (
    <>
      <AsideMenuItemRoleCheck roles={["ADMIN", "CUSTOMER_SERVICE", "CONTENT MANAGEMENT", "FINANCE"]}>
      {/* Dashboard */}
      <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            <AsideMenuItem
              to='/dashboard'
              icon='/media/icons/duotune/art/art002.svg'
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              fontIcon='bi-app-indicator'
              classes='btn btn-secondary btn-sm w-100'
            />
          </span>
        </div>
      </div>
      </AsideMenuItemRoleCheck>

      <AsideMenuItemRoleCheck roles={["ADMIN"]}>
      {/** Health Check */}
      <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section text-muted fs-8 ls-1'>
            <AsideMenuItem
              to='/healthCheck'
              icon='/media/icons/duotune/art/art002.svg'
              title='Health Check'
              fontIcon='bi-app-indicator'
              classes='btn btn-secondary btn-sm w-100'
            />
          </span>
        </div>
      </div>
      </AsideMenuItemRoleCheck>

      {/* Accounts */}
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      <AsideMenuItemRoleCheck roles={["ADMIN", "CUSTOMER_SERVICE"]}>
      {/* Accounts */}
      <div className='module-menu' tabIndex={0}>
        {/* <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            <button className='btn btn-secondary btn-sm w-100' disabled>
            <KTSVG
                        path='/media/icons/duotune/communication/com014.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
            Accounts
            </button>
          </span>
        </div>
      </div> */}
        <div className='menu-item'>
          <div className='menu-content bg-white- pb-2'>
            <span className='menu-section text-muted fs-8 ls-1'>
              <AsideMenuItem
                to='/accounts'
                icon='/media/icons/duotune/communication/com014.svg'
                title='Accounts'
                fontIcon='bi-app-indicator'
                classes='btn btn-secondary btn-sm w-100'
                disabled={true}
              />
            </span>
          </div>
        </div>
        <div className='items'>
          <AsideMenuItem
            to='/accounts/dashboard'
            icon='/media/icons/duotune/general/gen019.svg'
            title='Dashboard'
            fontIcon='bi-layers'
            classes=''
          />
          <AsideMenuItem
            to='/accounts/list'
            icon='/media/icons/duotune/communication/com005.svg'
            title='List'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/accounts/profile'
            icon='/media/icons/duotune/communication/com006.svg'
            title='Profile'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/accounts/create'
            icon='/media/icons/duotune/arrows/arr013.svg'
            title='Create'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/accounts/partners'
            icon='/media/icons/duotune/arrows/arr013.svg'
            title='Partners'
            fontIcon='bi-layers'
          />
          {/* <AsideMenuItem
            to='/accounts/edit'
            icon='/media/icons/duotune/art/art005.svg'
            title='Edit'
            fontIcon='bi-layers'
          /> */}
          <AsideMenuItem
            to='/accounts/import'
            icon='/media/icons/duotune/arrows/arr013.svg'
            title='Impot Accounts'
            fontIcon='bi-layers'
          />
        </div>
      </div>
      </AsideMenuItemRoleCheck>
      
      <AsideMenuItemRoleCheck roles={["ADMIN", "CONTENT MANAGEMENT"]}>
      {/* Operators */}
      <div className='module-menu' tabIndex={0}>
        {/* <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
          <button className='btn btn-secondary btn-sm w-100' disabled>Operators</button> </span>
        </div>
      </div> */}
        {/* <div className='menu-item'>

<div className='menu-content bg-white- pb-2'>
<span className='menu-section text-muted text-uppercase fs-8 ls-1'>
<AsideMenuItem
  to='/accounts'
  icon='/media/icons/duotune/communication/com014.svg'
  title='Accounts'
  fontIcon='bi-app-indicator'
  classes='btn btn-secondary btn-sm w-100'
/> */}
        <div className='menu-item'>
          <div className='menu-content bg-white- pb-2'>
            <AsideMenuItem
              to='/operators'
              icon='/media/icons/duotune/general/gen022.svg'
              title='Operators'
              fontIcon='bi-app-indicator'
              classes='btn btn-secondary btn-sm w-100'
              disabled={true}
            />
          </div>
        </div>

        <div className='items'>
          <AsideMenuItem
            to='/operators/dashboard'
            icon='/media/icons/duotune/general/gen019.svg'
            title='Dashboard'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/operators/list'
            icon='/media/icons/duotune/abstract/abs029.svg'
            title='List'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/operators/create'
            icon='/media/icons/duotune/arrows/arr013.svg'
            title='Create'
            fontIcon='bi-layers'
          />
          {/* <AsideMenuItem
        to='/operators/edit'
        icon='/media/icons/duotune/art/art005.svg'
        title='Edit'
        fontIcon='bi-layers'
      /> */}
        </div>
      </div>
      </AsideMenuItemRoleCheck>
      {/* Order Management */}
      <AsideMenuItemRoleCheck roles={["ADMIN", "ORDER MANAGEMENT", "REPORTING"]}>
      {/* Orders Management */}
      <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section'>
            <AsideMenuItem
              to='/ordersManagement'
              icon='/media/icons/duotune/art/art002.svg'
              title='Orders Management'
              fontIcon='bi-app-indicator'
              classes='front btn btn-secondary btn-sm w-100'
            />
          </span>
        </div>
      </div>
      </AsideMenuItemRoleCheck>
    
      <AsideMenuItemRoleCheck roles={["ADMIN", "CONTENT MANAGEMENT"]}>
      {/* Plans */}
      {/*  Plans */}
      <div className='module-menu' tabIndex={0}>
        {/* <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                      <button className='btn btn-secondary btn-sm w-100' disabled>Plans</button>
          </span>
        </div>
      </div> */}
        <div className='menu-item'>
          <div className='menu-content bg-white- pb-2'>
            <AsideMenuItem
              to='/plans'
              icon='/media/icons/duotune/general/gen025.svg'
              title='Plans'
              fontIcon='bi-app-indicator'
              classes='btn btn-secondary btn-sm w-100'
              disabled={true}
            />
          </div>
        </div>
        <div className='items'>
          <AsideMenuItem
            to='/plans/dashboard'
            icon='/media/icons/duotune/general/gen019.svg'
            title='Dashboard'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/plans/list'
            icon='/media/icons/duotune/abstract/abs029.svg'
            title='List'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/plans/create'
            icon='/media/icons/duotune/arrows/arr013.svg'
            title='Create'
            fontIcon='bi-layers'
          />
          {/* <AsideMenuItem
        to='/plans/edit'
        icon='/media/icons/duotune/art/art005.svg'
        title='Edit'
        fontIcon='bi-layers'
      /> */}
        </div>
      </div>
      </AsideMenuItemRoleCheck>
      <AsideMenuItemRoleCheck roles={["ADMIN", "CUSTOMER_SERVICE"]}>
      {/* Cards */}
      <div className='module-menu' tabIndex={0}>
        {/* <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
          <button className='btn btn-secondary btn-sm w-100' disabled>Cards </button>
          </span>
        </div>
      </div> */}
        <div className='menu-item'>
          <div className='menu-content bg-white- pb-2'>
            <AsideMenuItem
              to='/cards'
              icon='/media/icons/duotune/finance/fin002.svg'
              title='Cards'
              fontIcon='bi-app-indicator'
              classes='btn btn-secondary btn-sm w-100'
              disabled={true}
            />
          </div>
        </div>

        <div className='items'>
          <AsideMenuItem
            to='/cards/dashboard'
            icon='/media/icons/duotune/general/gen019.svg'
            title='Dashboard'
            fontIcon='bi-layers'
          />
          <AsideMenuItemWithSub
            to='/cards/sim'
            title='SIM Cards'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen022.svg'
          >
            <AsideMenuItem to='/cards/dashboard' title='dashboard' hasBullet={true} />
            <AsideMenuItem to='/cards/profile/orders' title='Orders' hasBullet={true} />
            <AsideMenuItem to='/cards/profile/scp' title='SIM card profiles' hasBullet={true} />
            <AsideMenuItem to='/cards/profile/import' title='SIM import' hasBullet={true} />
          </AsideMenuItemWithSub>

          <AsideMenuItemWithSub
            to='/cards/esim'
            title='eSIM'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/technology/teh001.svg'
          >
            <AsideMenuItem to='/cards/esim/dashboard' title='dashboard' hasBullet={true} />
            <AsideMenuItem to='/cards/esim/orders' title='Orders' hasBullet={true} />
            <AsideMenuItem to='/cards/esim/scp' title='eSIM esims' hasBullet={true} />
            <AsideMenuItem to='/cards/esim/import' title='eSIM import' hasBullet={true} />
            <AsideMenuItem to='cards/esim/eSIMGiftCards' title='eSIM Gift Cards' hasBullet={true} />
          </AsideMenuItemWithSub>

          <AsideMenuItemWithSub
            to='/cards/prepaidsim'
            title='Prepaid Cards'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen022.svg'
          >
            <AsideMenuItemWithSub to='cards/likecards' title='Likecards' hasBullet={true}>
              <AsideMenuItem to='cards/likecards/dashboard' title='dashboard' hasBullet={true} />
              <AsideMenuItem to='cards/likecards/orders' title='Orders' hasBullet={true} />
              <AsideMenuItem to='cards/likecards/providers' title='Providers' hasBullet={true} />
              <AsideMenuItem to='cards/likecards/category' title='Category' hasBullet={true} />
              <AsideMenuItem to='cards/likecards/products' title='Products' hasBullet={true} />
              <AsideMenuItem to='cards/likecards/giftCards' title='GiftCards' hasBullet={true} />
              {/* <AsideMenuItem to='cards/likecards/cards' title='Cards' hasBullet={true} /> */}
            </AsideMenuItemWithSub>
          </AsideMenuItemWithSub>
        </div>
      </div>
      </AsideMenuItemRoleCheck>
      <AsideMenuItemRoleCheck roles={["ADMIN", "CUSTOMER_SERVICE"]}>
      {/* AirAlo */}
      <div className='module-menu' tabIndex={0}>
        {/* <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
          <button className='btn btn-secondary btn-sm w-100' disabled>AirAlo </button>
          </span>
        </div>
      </div> */}
        <div className='menu-item'>
          <div className='menu-content bg-white- pb-2'>
            <AsideMenuItem
              to='/airalo'
              icon='/media/icons/duotune/maps/map010.svg'
              title='AirAlo'
              fontIcon='bi-app-indicator'
              classes='btn btn-secondary btn-sm w-100'
              disabled={true}
            />
          </div>
        </div>

        <div className='items'>
          <AsideMenuItem
            to='/airalo/dashboard'
            icon='/media/icons/duotune/general/gen019.svg'
            title='Dashboard'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/airalo/esims'
            icon='/media/icons/duotune/technology/teh001.svg'
            title='eSIMs'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/airalo/esims/import'
            icon='/media/icons/duotune/arrows/arr037.svg'
            title='Import'
            fontIcon='bi-layers'
          />
        </div>
      </div>
      </AsideMenuItemRoleCheck>
      <AsideMenuItemRoleCheck roles={["ADMIN"]}>
      {/* Advertisements */}
      <div className='module-menu' tabIndex={0}>
        {/* <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
          <button className='btn btn-secondary btn-sm w-100' disabled>Advertisements </button>
          </span>
        </div>
      </div> */}
        <div className='menu-item'>
          <div className='menu-content bg-white- pb-2'>
            <AsideMenuItem
              to='/advertisement'
              icon='/media/icons/duotune/ecommerce/ecm011.svg'
              title='Advertisements'
              fontIcon='bi-app-indicator'
              classes='btn btn-secondary btn-sm w-100'
              disabled={true}
            />
          </div>
        </div>

        <div className='items'>
          <AsideMenuItem
            to='/advertisement/list'
            icon='/media/icons/duotune/abstract/abs029.svg'
            title='Ads list'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/advertisement/create'
            icon='/media/icons/duotune/arrows/arr013.svg'
            title='Add new'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/advertisement/edit'
            icon='/media/icons/duotune/art/art005.svg'
            title='Edit'
            fontIcon='bi-layers'
          />
        </div>
      </div>
      </AsideMenuItemRoleCheck>
      <AsideMenuItemRoleCheck roles={["ADMIN", "FINANCE", "CUSTOMER_SERVICE"]}>
      {/* Payments */}
      <div className='module-menu' tabIndex={0}>
        {/* <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
          <button className='btn btn-secondary btn-sm w-100' disabled>Payments </button>
          </span>
        </div>
      </div> */}
        <div className='menu-item'>
          <div className='menu-content bg-white- pb-2'>
            <AsideMenuItem
              to='/payment'
              icon='/media/icons/duotune/finance/fin008.svg'
              title='Payments'
              fontIcon='bi-app-indicator'
              classes='btn btn-secondary btn-sm w-100'
              disabled={true}
            />
          </div>
        </div>

        <div className='items'>
          <AsideMenuItem
            to='/payment/dashboard'
            icon='/media/icons/duotune/general/gen019.svg'
            title='Dashboard'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/payment/list'
            icon='/media/icons/duotune/finance/fin001.svg'
            title='List'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/payment/finance'
            icon='/media/icons/duotune/finance/fin008.svg'
            title='Finance'
            fontIcon='bi-layers'
          />

          <AsideMenuItem
            to='/payment/paymentSheet'
            icon='/media/icons/duotune/finance/fin010.svg'
            title='Finance 2'
            fontIcon='bi-layers'
          />
          <AsideMenuItemRoleCheck roles={["ADMIN", "CUSTOMER_SERVICE"]}>
            <AsideMenuItem
              to='/payment/Corrections'
              icon='/media/icons/duotune/finance/fin002.svg'
              title='Corrections'
              fontIcon='bi-layers'
            />
          </AsideMenuItemRoleCheck>
          <AsideMenuItemRoleCheck roles={["ADMIN", "CUSTOMER_SERVICE"]}>
            <AsideMenuItem
              to='/payment/search'
              icon='/media/icons/duotune/general/gen021.svg'
              title='Search'
              fontIcon='bi-layers'
            />
          </AsideMenuItemRoleCheck>
        </div>
      </div>
      </AsideMenuItemRoleCheck>
      <AsideMenuItemRoleCheck roles={["ADMIN"]}>
      {/* Promo Codes */}
      <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            <AsideMenuItem
              to='/promo/list'
              icon='/media/icons/duotune/ecommerce/ecm011.svg'
              title='Promo Codes'
              fontIcon='bi-app-indicator'
              classes='btn btn-secondary btn-sm w-100'
            />
          </span>
        </div>
      </div>
      </AsideMenuItemRoleCheck>

      <AsideMenuItemRoleCheck roles={["ADMIN"]}>
      {/** Authorization */}
      <div className='module-menu' tabIndex={0}>
        <div className='menu-item'>
          <div className='menu-content bg-white- pb-2'>
            <span className='menu-section text-muted fs-8 ls-1'>
              <AsideMenuItem
                to='/authorization'
                icon='/media/icons/duotune/general/gen047.svg'
                title='Authorization'
                fontIcon='bi-app-indicator'
                classes='btn btn-secondary btn-sm w-100'
                disabled={true}
              />
            </span>
          </div>
        </div>

        <div className='items'>
          <AsideMenuItem
            to='/authorization/usersRoles'
            icon='/media/icons/duotune/abstract/abs027.svg'
            title='Users Roles'
            fontIcon='bi-layers'
          />
        </div>
      </div>
      </AsideMenuItemRoleCheck>

      <AsideMenuItemRoleCheck roles={["ADMIN"]}>
      {/* Notifications */}
      <div className='module-menu' tabIndex={0}>
        <div className='menu-item'>
          <div className='menu-content bg-white- pb-2'>
            <AsideMenuItem
              to='/notifications'
              icon='/media/icons/duotune/communication/com004.svg'
              title='Notifications'
              classes='btn btn-secondary btn-sm w-100'
              disabled={true}
            />
          </div>
        </div>
        <div className='items'>
          <AsideMenuItem
            to='/notifications/events'
            icon='/media/icons/duotune/coding/cod002.svg'
            title='Events'
            fontIcon='bi-layers'
          />
          
          <AsideMenuItem
            to='/notifications/types'
            icon='/media/icons/duotune/communication/com007.svg'
            title='Types'
            fontIcon='bi-layers'
          />
          {/* <AsideMenuItem
            to='/notifications/notifications'
            icon='/media/icons/duotune/general/gen045.svg'
            title='Notifications'
            fontIcon='bi-layers'
          /> */}
        </div>
      </div>
      </AsideMenuItemRoleCheck>
      {/* Reports */}
      <AsideMenuItemRoleCheck roles={["ADMIN", "REPORTING", "ORDER MANAGEMENT"]}>
      {/* Reports */}
      <div className='module-menu' tabIndex={0}>
        <div className='menu-item'>
          <div className='menu-content bg-white- pb-2'>
            <AsideMenuItem
              to='/reports'
              icon='/media/icons/duotune/finance/fin002.svg'
              title='Reports'
              classes='btn btn-secondary btn-sm w-100'
              disabled={true}
            />
          </div>
        </div>
        <div className='items'>
          <AsideMenuItem
            to='/reports/esim'
            icon='/media/icons/duotune/general/gen019.svg'
            title='Sim Reports'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/reports/payment'
            icon='/media/icons/duotune/general/gen019.svg'
            title='Payment Reports'
            fontIcon='bi-layers'
          />
          {/* <AsideMenuItem
            to='/reports/edit'
            icon='/media/icons/duotune/art/art005.svg'
            title='Edit'
            fontIcon='bi-layers'
          /> */}
        </div>
      </div>
        
      </AsideMenuItemRoleCheck>


      <AsideMenuItemRoleCheck roles={["ADMIN", "CONTENT MANAGEMENT"]}>
      {/* Configurations */}
      <div className='module-menu' tabIndex={0}>
        {/* <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
          <button className='btn btn-secondary btn-sm w-100' disabled>Configurations </button>
          </span>
        </div>
      </div> */}
        <div className='menu-item'>
          <div className='menu-content bg-white- pb-2'>
            <AsideMenuItem
              to='/configurations'
              icon='/media/icons/duotune/coding/cod009.svg'
              title='Configurations'
              classes='btn btn-secondary btn-sm w-100'
              disabled={true}
            />
          </div>
        </div>

        <div className='items'>
          <AsideMenuItem
            to='/configuration/library'
            icon='/media/icons/duotune/general/gen006.svg'
            title='Image Library'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/configuration/app'
            icon='/media/icons/duotune/coding/cod001.svg'
            title='App configurations'
            fontIcon='bi-layers'
          />
          <AsideMenuItemWithSub
            to='/configuration/Countries'
            title='Countries'
            fontIcon='bi-layers'
            icon='/media/icons/duotune/general/gen006.svg'
          >
            <AsideMenuItem to='/configuration/countries/create' title='Create' />
            <AsideMenuItem to='/configuration/countries' title='Consult' />
          </AsideMenuItemWithSub>
          <AsideMenuItem
            to='/configuration/web'
            icon='/media/icons/duotune/general/gen062.svg'
            title='Web configurations'
            fontIcon='bi-layers'
          />
        </div>
      </div>
      </AsideMenuItemRoleCheck>

      <AsideMenuItemRoleCheck roles={["ADMIN"]}>
      {/* Support */}
      <div className='module-menu' tabIndex={0}>
        {/* <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
          <button className='btn btn-secondary btn-sm w-100' disabled>Support </button>
          </span>
        </div>
      </div> */}
        <div className='menu-item'>
          <div className='menu-content bg-white- pb-2'>
            <AsideMenuItem
              to='/support'
              icon='/media/icons/duotune/general/gen044.svg'
              title='Support'
              classes='btn btn-secondary btn-sm w-100'
              disabled={true}
            />
          </div>
        </div>
        <div className='items'>
          <AsideMenuItem
            to='/support/tickets'
            icon='/media/icons/duotune/coding/cod002.svg'
            title='Tickets'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/support/chat'
            icon='/media/icons/duotune/communication/com007.svg'
            title='Chat'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/support/faq'
            icon='/media/icons/duotune/general/gen045.svg'
            title='FAQ'
            fontIcon='bi-layers'
          />
        </div>
      </div>
      </AsideMenuItemRoleCheck>

      {/* <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content bg-white- pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )

  
}


//  AsideMenuItemRoleCheck takes a list of roles and child components as props and returns the child components it has the same logic as the above function but it is more reusable and can be used in any component
export const AsideMenuItemRoleCheck = ({roles, children}: {roles: string[], children: React.ReactNode}) => {
  const [roles_, setRoles] = React.useState<string[]>([]);

  const getRoles = () => {
    // Step 1: Get Token from Local Storage
    const jwtToken = localStorage.getItem('kt-auth-react-v') || '';

    // Step 2: Decode the Token
    const decodedToken = decodeJWT(jwtToken);

    // Step 2: Decode Token

    // Step 3: Extract Roles from Decoded Token
    // const roles_ = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
    // .map((role: string) => role.toUpperCase())
    // .filter((role: string) => role !== 'ADMIN');
    let roles_ = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
    if(roles_)
    {
      // if roles type string
      if(typeof roles_ === 'string')
      {
        roles_ = [roles_];
      } else 
      roles_ = roles_.map((role: string) => role.toUpperCase());
    }
    else 
    {
      roles_ = [];
      alert("you don't have any roles")
      //logout 
      localStorage.removeItem('kt-auth-react-v');
      window.location.href = '/auth/login';
    }
    // Step 4: Set Roles to State
    setRoles(roles_);

    // Function to decode JWT token (assuming it's a standard JWT)
    function decodeJWT(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(window.atob(base64));
    }
}
  React.useEffect(() => {
    getRoles();
  }, []);

  if (roles_.some(role => roles.includes(role.toUpperCase())))
    return <>{children}</>
  else
  return <></>
}

