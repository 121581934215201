import {REACT_APP_API_MOBILY, REACT_APP_API_SALAM, REACT_APP_API_SIM} from '../config'
// import PromoCode from '../models/PromoCode'
import axios from './interceptors/axios.global'
const api = `${REACT_APP_API_SIM}/v2`
const api_salam = `${REACT_APP_API_SALAM}`
const api_mobily = `${REACT_APP_API_MOBILY}`

// /api/v{version}/Statistics/GetOrderData
const GetOrderData = async (params: any) => {
  // console.log('params:', params)
  // console.log('orderStatus:', orderStatus)
  // console.log('providerId:', providerId)
  // if (providerId && orderStatus)
  //   return axios.get(
  //     `${api}/Statistics/GetOrderData?ProviderIds=${providerId}&pageNumber=${pageNumber}&PageSize=${PageSize}&OrderStatus=${orderStatus}`
  //   )
  // else if (providerId)
  //   return axios.get(
  //     `${api}/Statistics/GetOrderData?pageNumber=${pageNumber}&PageSize=${PageSize}&ProviderIds=${providerId}`
  //   )
  // else if (orderStatus)
  //   return axios.get(
  //     `${api}/Statistics/GetOrderData?pageNumber=${pageNumber}&PageSize=${PageSize}&OrderStatus=${orderStatus}`
  //   )
  // else
  //   return axios.get(`${api}/Statistics/GetOrderData?pageNumber=${pageNumber}&PageSize=${PageSize}`)
  return axios.get(`${api}/Statistics/GetOrderData`, {params: params})
}

// /api/v{version}/Statistics/GetStatisticsNumber
const GetStatisticsNumber = async (params: any) => {
  return axios.get(`${api}/Statistics/GetStatisticsNumber`, {params: params})
}

const getOrderDataByPhoneNumbrt = async (data: {
  numbers: {phoneNumber: String; countryCode: String}[]
}) => {
  console.log('numbers:', data)
  return axios.post(`${api}/Statistics/getOrderDataByPhoneNumbrt`, data)
}

// curl -X POST "https://test.sim.tygo.net/api/v2/Order/ChanegOrderState" -H  "accept: text/plain; ver=2.0" -H  "Content-Type: application/json; ver=2.0" -d "{\"tygoOrderId\":\"string\",\"newOrderStateCode\":\"string\"}"
const ChanegOrderState = async (data: {tygoOrderId: String; newOrderStateCode: String}) => {
  return axios.post(`${api}/Order/ChanegOrderState`, data)
}


const GetOrderLogsByUserId = async (data: {UserIds: String[], pageNumber: Number, PageSize: Number}) => {
  return axios.get(`${api}/Statistics/GetUserLog?UserIds=${data.UserIds}&pageNumber=${data.pageNumber}&PageSize=${data.PageSize}`)
}

const getEsimData = async ({type, tygoOrderId, providerOrderId} : {type: String, tygoOrderId: String, providerOrderId: String}) => {
  // https://test.salam.tygo.net/api/Order/GetOrderByIdAdmin?tygoOrderId=e2bf434a-b7b5-496e-bbfd-e98233f6a188&providerOrderId=0aa39cf8-43fb-4fed-a590-671562177b46
  if (type === 'salam') {
    return axios.get(`${api_salam}/Order/GetOrderByIdAdmin?tygoOrderId=${tygoOrderId}&providerOrderId=${providerOrderId}`)
  }
  if (type === 'mobily') {
    return axios.get(`${api_mobily}/Order/GetOrderByIdAdmin?tygoOrderId=${tygoOrderId}&providerOrderId=${providerOrderId}`)
  }
  //trow error
  return null
}

//run stored procedure with increased timeout
const runStoredProcdureByNameWithParam = (data: any) => {
  return axios.post(`${api}.0/Statistics/RunStoredProcdureByNameWithParam`, data, {
    timeout: 600000 // 10 minutes timeout
  })
}


const SimService = {
  GetStatisticsNumber,
  GetOrderData,
  getOrderDataByPhoneNumbrt,
  ChanegOrderState,
  GetOrderLogsByUserId,
  getEsimData,
  runStoredProcdureByNameWithParam
}
export default SimService
